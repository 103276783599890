import React, {
    createContext,
    ReactNode,
    useContext,
    useEffect,
    useState
} from "react";

import
{
    SystemStatus,
    PaginationRequest
} from '@ews/react-data';

import
{
    LoadingContextType
} from "../Loading";

import EventEmitter from "events";

import
{
    Channel,
    Websocket,
    client as websocketClient,
    AuthRequest
} from "@ews/websocket-service";

type Window = {
    env: {
        APP_SERVICE_URL: string;
        APP_HEARTBEAT_INTERVAL: number;
    };
};

const { env } = window as unknown as Window;

const toggle: LoadingContextType = () => { };

export type ReactDataClientError = {
    url: string,
    body: {
        errorCode: number,
        error: string;
    };
};

class ReactDataProvider extends EventEmitter
{

    protected _throttling: number = 0;

    protected client: ReturnType<typeof websocketClient>;
    protected channel: Channel;
    protected status: SystemStatus = SystemStatus.UNKNOWN;

    protected subscriptions: Record<string, true> = {};
    protected cache: Record<string, any> = {};

    constructor(protected serviceUri: string, protected heartbeatInterval: number = 10000)
    {
        super();
        [this.channel, this.client] = this.connect();
    }

    protected throttling(increase: number)
    {
        if (increase) {
            this._throttling = Math.min(this._throttling += increase, 10000);
        } else {
            this._throttling = 0;
        }

        return this._throttling;
    }

    protected setStatus(status: SystemStatus)
    {
        this.status = status;
        this.emit("status", this.status);
    }

    getStatus = () => this.status;

    protected bindChannel()
    {
        this.channel.on("message:publish", (msg: any, ...topics: string[]) =>
        {
            for (const topic of topics) {
                this.cache[topic] = msg;
                this.emit(`resolve:${topic}`, msg);
            }
        });

        this.channel.on("message:auth", (response: AuthRequest) =>
        {
            if (response.type === "token") this.emit("channel:unlocked");
            this.emit("auth:response", response);
        });

        this.channel.on("error", (err) => console.log(err));
        this.channel.on("close", () => setTimeout(() => this.connect(), this.throttling(1000)));
        this.channel.on("open", () =>
        {
            this.setStatus(SystemStatus.ONLINE);
            this.throttling(0);
        });

    }
    protected subscribeChannel()
    {
        const topics = Object.keys(this.subscriptions);
        if (topics.length) this.client.bind(...topics);
    }

    protected connect(): [Channel, ReturnType<typeof websocketClient>]
    {
        this.setStatus(SystemStatus.OFFLINE);

        // if (this.channel) {
        //     this.channel.close();
        // }

        const websocket = new Websocket(this.serviceUri);

        this.channel = new Channel(websocket);
        this.client = websocketClient(this.channel);

        this.bindChannel();
        this.once("channel:unlocked", () => this.subscribeChannel());

        this.client.setupHeartbeat(this.heartbeatInterval);

        return [this.channel, this.client];

    }

    subscribe(topic: string, callback: (data: any) => void)
    {
        this.on(`resolve:${topic}`, callback);
        this.subscriptions[topic] = true;
    }

    unsubscribe(topic: string, callback: (data: any) => void)
    {
        this.off(`resolve:${topic}`, callback);
    }

    bind(topic: string, callback: (data: any) => void)
    {
        this.subscribe(topic, callback);

        if (topic in this.cache) {
            if (this.cache[topic]) callback(this.cache[topic]);
        } else {
            this.cache[topic] = undefined;
            this.client.bind(topic);
        }
    }

    modify<T, R = T>(data: T, topic: string, loadingToggle: LoadingContextType = toggle): Promise<R>
    {
        return new Promise((resolve, reject) =>
        {
            this.channel.once(`message:modified:${topic}`, (data: R) =>
            {
                if (typeof data === 'string') {
                    reject(data);
                } else {
                    resolve(data);
                }
                loadingToggle(false);
            });

            loadingToggle(true);
            this.client.modify(data, topic);
        });

    };

    fetch<R>(filterCriteria: PaginationRequest, topic: string, loadingToggle: LoadingContextType = toggle): Promise<R>
    {
        return new Promise((resolve, reject) =>
        {
            this.channel.once(`message:fetch:${topic}`, (data: R) =>
            {
                if (typeof data === 'string') {
                    reject(data);
                } else {
                    resolve(data);
                }
                loadingToggle(false);
            });

            loadingToggle(true);
            this.client.fetch(filterCriteria, topic);
        });
    };

    create<T, R = T>(data: T, topic: string, loadingToggle: LoadingContextType = toggle): Promise<R>
    {
        return new Promise((resolve, reject) =>
        {
            this.channel.once(`message:created:${topic}`, (data: R) =>
            {
                if (typeof data === 'string') {
                    reject(data);
                } else {
                    resolve(data);
                }
                loadingToggle(false);
            });

            loadingToggle(true);
            this.client.create(data, topic);
        });
    };

    delete<T, R = T>(topic: string, loadingToggle: LoadingContextType = toggle): Promise<R>
    {
        return new Promise((resolve, reject) =>
        {
            this.channel.once(`message:deleted:${topic}`, (data: R) =>
            {
                if (typeof data === 'string') {
                    reject(data);
                } else {
                    resolve(data);
                }
                loadingToggle(false);
            });

            loadingToggle(true);
            this.client.remove(topic);
        });
    };

    operate<T, R = T>(data: T, topic: string, loadingToggle: LoadingContextType = toggle): Promise<R>
    {
        return new Promise((resolve, reject) =>
        {
            this.channel.once(`message:confirm:${topic}`, (data: R) =>
            {

                if (typeof data === 'string') {
                    reject(data);
                } else {
                    resolve(data);
                }
                loadingToggle(false);
            });

            loadingToggle(true);
            this.client.operate(data, topic);
        });
    };

    auth(request: AuthRequest, loadingToggle: LoadingContextType = toggle): Promise<AuthRequest>
    {
        return new Promise((resolve, reject) =>
        {
            this.channel.once('message:auth', async (data: AuthRequest) =>
            {
                if (data.type === 'status' && "error" in data) {
                    reject(data.error);
                } else {
                    resolve(data);
                }

                loadingToggle(false);
            });

            loadingToggle(true);

            this.client.auth(request);
        });
    }

}

export type ProviderInterface = ReactDataProvider;

const reactDataProvider = new ReactDataProvider(
    env.APP_SERVICE_URL,
    env.APP_HEARTBEAT_INTERVAL || 10000
);

type WebsocketContextType = ReactDataProvider;
const WebsocketContext = createContext<WebsocketContextType>(reactDataProvider);

export const useWebsocket = () =>
{
    return useContext(WebsocketContext);
};

export const ReactData: React.FC<{ children: ReactNode; }> = ({ children }) =>
{
    return (
        <WebsocketContext.Provider value={reactDataProvider} >
            {children}
        </WebsocketContext.Provider >
    );
};

export const useTopicData = <T,>(topic: string, defaultValue: T): T =>
{
    const provider = useWebsocket();
    const [topicData, setTopicData] = useState<T>(defaultValue);

    useEffect(() =>
    {
        provider.bind(`${topic}`, setTopicData);

        return () =>
        {
            provider.unsubscribe(`${topic}`, setTopicData);
        };

    }, [topic, provider]);

    return topicData;
};