import
{
    IonAccordion,
    IonAccordionGroup,
    IonItem
} from "@ionic/react";

import
{
    SystemElement,
    SystemGroup
} from "../../../ReactData/system";

import React, { useRef } from "react";

import listStyle from "../GruppList.module.scss";

import
{
    HeaderOption,
} from "../types";

import
{
    Element
} from "./Element";

import
{
    isValidDeviceNumber,
    NoDeviceNumber,
    NumberGroup
} from "@ews/zlt-events";

import LongPress from "../../../LongPress";

import
{
    ReactClickEvent
} from "../../../types";

type GroupProps = {
    headers: HeaderOption[],
    row: SystemGroup;
    color?: string;
    onMenu?: (group: SystemGroup, element?: SystemElement, e?: ReactClickEvent) => void;
};

const isFloodingZone = (row: SystemGroup) => row.numberGroup === NumberGroup.FloodingZone;

const isGroupElement = (row: SystemGroup) =>
{
    if ((row.elements?.length || 0) <= 1) {
        const element = row.elements?.length ? row.elements[0] : { id: NoDeviceNumber };
        return (!isValidDeviceNumber(element.id || 0));
    }
    return false;
};

export const Group: React.FC<GroupProps> = ({
    row,
    headers,
    color,
    onMenu
}) =>
{
    const accordionRef = useRef<null | HTMLIonAccordionGroupElement>(null);

    const menuWrapper = (e?: ReactClickEvent) =>
    {
        if (onMenu) {
            onMenu(row, undefined, e);
        }
    };

    if (isGroupElement(row)) {
        return (
            <Element
                headers={headers}
                color={'light'}
                slot="header"
                group={row}
                onMenu={onMenu}
            />
        );
    }

    if (isFloodingZone(row)) {
        return <>
            {row.elements?.map(element => <Element
                headers={headers}
                color={'light'}
                slot="header"
                row={element}
                group={row}
                onMenu={onMenu}
            />)}
        </>;
    }

    return (
        <IonAccordionGroup ref={accordionRef} onIonChange={(e) =>
        {
            if (accordionRef.current) accordionRef.current.value = e.detail.value;
        }} >

            <IonAccordion toggleIconSlot={"start"} value={`${row.id}`} >

                <IonItem
                    slot="header"

                    color={'light'}
                    lines="full"
                >
                    <div
                        className={`${listStyle.row}`}
                        {...LongPress(menuWrapper)}
                        onContextMenu={menuWrapper}
                    >
                        {headers.map((header, index) => 
                        {
                            const { render } = header;
                            return <React.Fragment key={index}>{render(row)}</React.Fragment>;

                        })}

                    </div>
                </IonItem >
                {row.elements?.map((element: SystemElement, index: number) => (
                    <Element
                        headers={headers}
                        key={index}
                        slot="content"
                        row={element}
                        group={row}
                        onMenu={onMenu}
                    />
                ))}
            </IonAccordion>
        </IonAccordionGroup>
    );
};

