import
{
    useL10n
} from "@ews/react-localization-context";

import
{
    FC, useState
} from "react";

import
{
    Sidebar,
    SidebarSection
} from "./Sidebar";

import type {
    MenuToggleProps
} from "../types";

import
{
    IonItem,
    IonLabel
} from "@ionic/react";

import
{
    SystemElement,
    SystemGroup,
    useOperateSystem
} from "../../ReactData/system";

import
{
    Command,
    //Command,
    Commands,
    getTranslationKey,
    groupOperate,
    // groupTypeToNumberGroup,
    // type GroupTypes
} from '@ews/zlt-operate';

import { AuthorizationLevel, NoDeviceNumber, NumberGroup, formatLogicalAddress } from "@ews/zlt-events";
import { useAuthorization } from "../../Authorization";
import { ReactClickEvent } from "../../types";
import { SystemOperate, SystemRTM } from "@ews/react-data";

export type OperateTarget = (SystemGroup | SystemElement);

export type SelectRange = {
    begin?: OperateTarget,
    end?: OperateTarget;
};

type OperateSidebarProps = MenuToggleProps & {
    target: OperateTarget;
    range?: SelectRange;
    onRangeSelect?: (target: OperateTarget) => SelectRange;
};

type OperationItemProps = {
    title: string,
    disabled?: boolean;
    onClick?: (e: ReactClickEvent) => void;
};

const OperationItem: FC<OperationItemProps> = ({
    title,
    disabled = false,
    onClick
}) =>
{
    const { translate: t } = useL10n();

    return (<IonItem disabled={disabled} detail={false} onClick={onClick} button={true} >
        <IonLabel>{t(title)}</IonLabel>

    </IonItem>);
};

const getNumberGroup = (target: OperateTarget): NumberGroup =>
{
    return target.numberGroup!;
};

export const getAddress = (target: OperateTarget) =>
{
    if ("groupId" in target) {
        return {
            zone: target.groupId!,
            element: target.id!
        };
    }
    return {
        zone: target.id!,
        element: NoDeviceNumber
    };
};

const getSystemId = (target: OperateTarget) =>
{
    return target.systemId;
};

export const validOperations = (authorizationLevel: AuthorizationLevel, begin: OperateTarget, end?: OperateTarget) =>
{
    const operations: Set<string> = new Set<string>();

    for (const target of [begin, end]) {

        if (target) {

            const numberGroup = getNumberGroup(target);

            const address = getAddress(target);
            const properties = target.properties1 || 0;
            const rtm = target.rtm?.map((v: SystemRTM) => v.eventKindType!) || [];

            for (const command of Commands) {
                if (groupOperate(numberGroup, command, properties, authorizationLevel, address, rtm)) {
                    operations.add(command);
                }
            }
        }
    }

    return Array.from(operations);
};

const Operate: FC<OperateSidebarProps> = ({
    onMenuToggle,
    target,
    range,
    onRangeSelect
}) =>
{
    const { authorizationLevel } = useAuthorization();
    const operate = useOperateSystem();
    const { translate: t } = useL10n();
    const [currentRange, setRange] = useState<SelectRange>({ ...range });

    const formatRange = (begin: OperateTarget, end: OperateTarget) =>
    {

        const from = getAddress(begin);
        const to = getAddress(end);

        return `${formatLogicalAddress(from.zone, from.element, 1)} - ${formatLogicalAddress(to.zone, to.element, 1)}`;
    };

    const rangeTitle = currentRange?.begin ? (currentRange?.end ? formatRange(currentRange.begin, currentRange.end) : 'OPER_RANGE_END') : 'OPER_RANGE_BEGIN';
    const rangeDisabled = Boolean(currentRange?.begin && currentRange?.end);

    const operations = validOperations(authorizationLevel, currentRange.begin || target, currentRange.end);

    const handleRangeSelect = () =>
    {
        if (onRangeSelect) {
            const range = onRangeSelect(target);
            setRange(range);
        }
    };

    const handleOperate = async (command: string, e: ReactClickEvent) =>
    {
        const begin = currentRange.begin || target;
        const end = currentRange.end || begin;

        const systemId = getSystemId(begin);

        const from = getAddress(begin);
        const to = getAddress(end);

        //const type = getType(begin);
        const numberGroup = getNumberGroup(begin);

        await operate(systemId!, {
            command,
            numberGroup,
            firstZone: from.zone,
            lastZone: to.zone,
            firstElement: from.element,
            lastElement: to.element
        } as SystemOperate);

        onMenuToggle && onMenuToggle(e);

    };

    return (
        <Sidebar title={''} onMenuToggle={onMenuToggle}>
            <SidebarSection grow>
                {operations.map((operation, index) =>
                    <OperationItem key={index} onClick={(e) => handleOperate(operation, e)} title={t(getTranslationKey(operation as Command))} />
                )}
                {range && <OperationItem disabled={rangeDisabled} onClick={handleRangeSelect} title={t(rangeTitle)} />}
            </SidebarSection>
        </Sidebar>
    );

};

export default Operate;