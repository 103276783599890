import { IonButton, IonCheckbox, IonCol, IonGrid, IonItem, IonLabel, IonRow } from "@ionic/react";
import { useEffect, useRef, useState } from "react";
import FilterList from "../../../FilterList/FilterList";

import { useL10n } from "@ews/react-localization-context";

import List from "../../../List/List";
import { pageRange } from "../../../List/Pagination/pagerange";

import { PaginationRequest, permissionAllowed, SystemMeasurements, SystemOperate } from "@ews/react-data";
import { AuthorizationLevel, formatLogicalAddress, loopType, NumberGroup } from "@ews/zlt-events";
import { useMyDateFormat, useMyPermissions } from "../../../Login/LoginProvider";
import { useFetchSystemMeasurement, useOperateSystem } from "../../../ReactData/system";
import { useIsMobile } from "../../../Responsive";
import DatePicker, { dateRange, dateStringToValue, valueToDateString } from "../../../Settings/SettingsComponents/SettingsRangeInput/components/DatePicker/DatePicker";
import SettingsZoneRangeInput from "../../../Settings/SettingsComponents/SettingsRangeInput/components/Zone/SettingsZoneRangeInput";
import { Filter } from "../types/reportTypes";
import analogueStyle from "./AnalogueValues.module.scss";
import { AnalogueFilterKeys, AnalogueValuesProps } from "./types";
import { useAuthorization } from "../../../Authorization";

const defaultRange = dateRange(new Date(), 7);

const AnalogueValues: React.FC<AnalogueValuesProps> = ({
    system,
    isFilterOpen,
    onIsOpen
}) =>
{
    const filterData = useFetchSystemMeasurement();
    const { translate: t } = useL10n();
    const dateFormat = useMyDateFormat();
    const mobileView = useIsMobile();
    const operate = useOperateSystem();
    const { authorizationLevel } = useAuthorization();
    const permissions = useMyPermissions();

    const page = useRef<number | undefined>();
    const numberOfPages = useRef<number | undefined>();
    const numberOfItemsPerPage = useRef(pageRange[1]);
    const [list, setList] = useState<SystemMeasurements[]>([]);
    const [details, showDetails] = useState(false);

    const standardFilter: Filter<AnalogueFilterKeys> = {
        numberGroup: [NumberGroup.Zone, NumberGroup.ActuationDevice, NumberGroup.AlarmingDevice].join(","),
        created: valueToDateString(defaultRange),
        sortCriteria: "created",
        sortOrder: "DESC"
    };

    const [currentState, setState] = useState<Filter<AnalogueFilterKeys>>(standardFilter);

    async function getFilteredData(id: string, filter: PaginationRequest & AnalogueFilterKeys)
    {
        const response = await filterData(id, filter);
        if (!response) return;

        const { results, currentPage, pageSize, totalCountOfPages } = response;

        page.current = currentPage;
        numberOfPages.current = totalCountOfPages;
        numberOfItemsPerPage.current = pageSize;

        setList(results);
    }


    useEffect(() =>
    {
        const { id } = system;
        if (!id) return;

        getFilteredData(id, currentState);

    }, [currentState, system]);

    let isAllowedMeasurements = (authorizationLevel > AuthorizationLevel.LEVEL2) || permissionAllowed(permissions.permitAccessLevel3!);;
    if (!isAllowedMeasurements) {
        isAllowedMeasurements = Boolean(permissions.detectorValuesAccessLevel2 && (authorizationLevel > AuthorizationLevel.LEVEL1));
    }

    if (!isAllowedMeasurements) return <></>;

    const checkboxWidth = { minWidth: "100%" };

    const numberGroupIsChecked = (numberGroup: NumberGroup) =>
    {
        const currentFilter = { ...currentState };
        const selectedGroups = (currentFilter.numberGroup || "").split(",").filter(v => v).map(v => parseInt(v));
        return selectedGroups.indexOf(numberGroup) >= 0;
    };

    const handleNumberGroup = (numberGroup: NumberGroup, checked: boolean) =>
    {
        const currentFilter = { ...currentState };
        const selectedGroups = (currentFilter.numberGroup || "").split(",").filter(v => v).map(v => parseInt(v));
        const index = selectedGroups.indexOf(numberGroup);

        if (index >= 0) selectedGroups.splice(index, 1);
        if (checked) selectedGroups.push(numberGroup);

        if (selectedGroups.length) {
            currentFilter.numberGroup = selectedGroups.join(",");
        } else {
            currentFilter.numberGroup = `${NumberGroup.Undefined}`;
        }

        console.log(currentFilter);
        setState({ ...currentFilter });
    };

    return <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
        <FilterList
            onReset={() => setState(standardFilter)}
            isDetailsHidden={false}
            onIsOpen={onIsOpen}
            isOpen={isFilterOpen}
            details={<>
                <IonCol>
                    <IonGrid style={{ overflowY: "auto" }}>
                        <IonRow>
                            <IonCol style={{ border: "0" }}>
                                <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                    <IonLabel style={{ maxWidth: "fit-content" }}>{t("Filter")}</IonLabel>
                                </div>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            {/* <IonCol size="12" style={{ display: "flex", justifyContent: "start", alignItem: "center", border: "0", ...checkboxWidth }} >

                                <IonCheckbox
                                    style={{ margin: 0, padding: 0, width: "fit-content" }}

                                    onIonChange={({ detail }) =>
                                    {
                                        const checked = detail.checked;
                                        console.log(detail);
                                    }}>
                                    {" "}
                                </IonCheckbox>
                                <IonLabel style={{ margin: "0 10px", verticalAlign: "middle" }}>{t('All')}</IonLabel> 
                            </IonCol> */}
                            <IonCol size="12" style={{ display: "flex", justifyContent: "start", alignItem: "center", border: "0", ...checkboxWidth }} >

                                <IonCheckbox
                                    checked={numberGroupIsChecked(NumberGroup.Zone)}
                                    style={{ margin: 0, padding: 0, width: "fit-content" }}
                                    onIonChange={({ detail }) => handleNumberGroup(NumberGroup.Zone, detail.checked)}
                                >
                                    {" "}
                                </IonCheckbox>
                                <IonLabel style={{ margin: "0 10px", verticalAlign: "middle" }}>{t('[SID_MNU_TAB_GROUPS]')}</IonLabel>
                            </IonCol>
                            <IonCol size="12" style={{ display: "flex", justifyContent: "start", alignItem: "center", border: "0" }} >

                                <IonCheckbox
                                    checked={numberGroupIsChecked(NumberGroup.ActuationDevice)}
                                    style={{ margin: 0, padding: 0, width: "fit-content" }}
                                    onIonChange={({ detail }) => handleNumberGroup(NumberGroup.ActuationDevice, detail.checked)}
                                >
                                    {" "}
                                </IonCheckbox>
                                <IonLabel style={{ margin: "0 10px", verticalAlign: "middle" }}>{t('[SID_MNU_TAB_CONTROLS]')}</IonLabel>
                            </IonCol>
                            <IonCol size="12" style={{ display: "flex", justifyContent: "start", alignItem: "center", border: "0" }} >

                                <IonCheckbox
                                    checked={numberGroupIsChecked(NumberGroup.AlarmingDevice)}
                                    style={{ margin: 0, padding: 0, width: "fit-content" }}
                                    onIonChange={({ detail }) => handleNumberGroup(NumberGroup.AlarmingDevice, detail.checked)}
                                >
                                    {" "}
                                </IonCheckbox>
                                <IonLabel style={{ margin: "0 10px", verticalAlign: "middle" }}>{t('[SID_MNU_TAB_AL_DEVICES]')}</IonLabel>
                            </IonCol>



                        </IonRow>
                    </IonGrid>
                </IonCol>
                <IonCol size="3">
                    <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                        <IonLabel style={{ maxWidth: "fit-content" }}>{t("Date")}</IonLabel>
                    </div>
                    <DatePicker
                        className={analogueStyle.dateTime}
                        childText={{ start: "start", end: "end" }}
                        value={dateStringToValue(currentState.created!)}
                        divider={false}
                        position="row"
                        onChange={(range) =>
                        {
                            const currentFilter = { ...currentState };
                            currentFilter.created = valueToDateString(range);
                            setState({ ...currentFilter });
                        }}
                    />
                </IonCol>
                <IonCol sizeLg="3" sizeMd="1" sizeSm="1">
                    <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                        <IonLabel style={{ maxWidth: "fit-content" }}>{t("Element")}</IonLabel>
                    </div>
                    <SettingsZoneRangeInput
                        lines="none"
                        divider={false}
                        labels={{ start: "start", end: "end" }}
                        inputLabels={{ parent: "Nummer", children: "Element" }}
                        position={{ parent: "row", children: "line" }}
                        reset={false}
                        onChange={(value) => 
                        {
                            const currentFilter = { ...currentState };
                            currentFilter.zone = `${value.startZoneElement}`;

                            if (value.endZoneElement) {
                                currentFilter.zone = `${value.startZoneElement},${value.endZoneElement}`;
                            }

                            setState({ ...currentFilter });
                        }}
                    />
                </IonCol>
                <IonCol style={{ display: 'flex', flexDirection: "column", alignItems: "end" }}>
                    <IonRow >
                        <IonButton
                            color={"success"}
                            onClick={async () =>
                            {
                                const { id } = system;
                                if (!id) return;
                                await operate(id, { command: 'MEASURE' } as SystemOperate);
                            }}
                        >
                            <span> {`${t("Load")} ${t("Measures")}`}</span>
                        </IonButton>
                    </IonRow>
                    <IonItem style={{ flexGrow: 1, alignContent: "end" }} lines="none">
                        <IonCheckbox
                            style={{ margin: 0, padding: 0, width: "fit-content" }}

                            onIonChange={({ detail }) =>
                            {
                                showDetails(detail.checked);
                            }}>
                            {" "}
                        </IonCheckbox>
                        <IonLabel style={{ margin: "0 10px", verticalAlign: "middle", width: "fit-content" }}>{t('Show history')}</IonLabel>
                    </IonItem>

                </IonCol>
            </>
            }
        >
        </FilterList >
        <List
            rows={list}
            sort={
                currentState.sortCriteria ? {
                    key: currentState.sortCriteria,
                    direction: currentState.sortOrder || "ASC"
                } : undefined
            }
            onSort={(sortCriteria, sortOrder) => setState({ ...currentState, sortCriteria, sortOrder })}

            currentPage={page.current}
            numberOfPages={numberOfPages.current}

            onPageChange={(page) => setState({ ...currentState, page })}

            //maxHeight={mobileView ? "86vh" : "47vh"}
            headers={[
                {
                    title: 'Time.', key: 'created', className: `${analogueStyle["col"]} ${mobileView ? analogueStyle['sizeFull'] : analogueStyle["size3"]}`, both: true, child: ({ created }) =>
                    {
                        return <>
                            {dateFormat(new Date(created))}
                        </>;
                    }
                },
                {
                    title: 'Element.', key: 'zone,element', className: `${analogueStyle["col"]} ${mobileView ? analogueStyle['sizeFull'] : analogueStyle["size2"]}`, both: true, child: ({ zone, element, panelNumber }) =>
                    {
                        return <>{formatLogicalAddress(zone, element, panelNumber)}</>;
                    }
                },
                ...(details ? [5, 4, 3, 2, 1, 0].map(n =>
                {
                    return {
                        title: `-${n + 1}MNTH`, key: `avg${n}`, className: `${analogueStyle["col"]} ${mobileView ? analogueStyle['sizeFull'] : analogueStyle["size1_5"]}`, both: (true as true), child: ((row: SystemMeasurements) =>
                        {
                            const value = row[`avg${n}` as keyof SystemMeasurements];
                            const month = row[`month${n}` as keyof SystemMeasurements];
                            const year = row[`year${n}` as keyof SystemMeasurements];
                            return value ? <>{`${month}`.padStart(2, "0")}.{year}: {value}</> : <></>;
                        })
                    };
                })
                    :
                    []
                ),
                { title: 'Current value', key: 'analogueValue', className: `${analogueStyle["col"]} ${mobileView ? analogueStyle['sizeFull'] : analogueStyle["size0"]}`, both: true, child: ({ analogueValue }) => analogueValue < 0xff ? analogueValue : "..." },
                { title: 'Alarming value', key: 'alarmThreshold', className: `${analogueStyle["col"]} ${mobileView ? analogueStyle['sizeFull'] : analogueStyle["size0"]}`, both: true, child: ({ alarmThreshold }) => alarmThreshold < 0xff ? alarmThreshold : "..." },
                { title: 'Dirt%', key: 'value', className: `${analogueStyle["col"]} ${mobileView ? analogueStyle['sizeFull'] : analogueStyle["size0"]}`, both: true, child: ({ value }) => value < 0xff ? value : "..." },
                { title: '+12MNTH', key: 'prediction12m', className: `${analogueStyle["col"]} ${mobileView ? analogueStyle['sizeFull'] : analogueStyle["size0"]}`, both: true, child: ({ prediction12m }) => prediction12m < 0xff ? prediction12m : "..." },
                { title: 'Maint', key: 'monthsLeft', className: `${analogueStyle["col"]} ${mobileView ? analogueStyle['sizeFull'] : analogueStyle["size0"]}`, both: true, child: ({ monthsLeft }) => monthsLeft < 0xff ? monthsLeft : "..." },
                { title: 'Element type', key: 'elementType', both: true, child: ({ elementType }) => <>{t(loopType(elementType))}</> },
                { title: 'Element text', key: 'text', both: true, },
            ]}
        />
    </div >;
};

export default AnalogueValues;

