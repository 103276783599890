import
{
    Capacitor
} from '@capacitor/core';

import
{
    Geolocation,
    Position
} from '@capacitor/geolocation';

import
{
    useEffect,
    useState
} from 'react';

import
{
    Polygon
} from "geojson";

import inside from 'point-in-polygon';

export const locationInArea = (location: Position, area: Polygon) =>
{
    const point = [location.coords.longitude, location.coords.latitude];
    const polygon = area.coordinates.slice().pop() as number[][];

    return inside(point, polygon);

};

export const getGeoLocation = async () =>
{
    return await Geolocation.getCurrentPosition();
};

let timeout: NodeJS.Timeout;

export const useGeoLocation = (refresh: number = 0) =>
{
    //if (Capacitor.getPlatform() === 'web') refresh = 0;

    const [position, setPosition] = useState<Position | null>(null);

    useEffect(() =>
    {
        const updatePosition = () =>
        {
            getGeoLocation().then((current) =>
            {
                setPosition((position) =>
                {
                    return current;
                    // return (current.coords.latitude !== position?.coords.latitude ||
                    //     current.coords.longitude !== position?.coords.longitude) ? current : position;
                });

                if (refresh) {
                    clearTimeout(timeout);
                    timeout = setTimeout(updatePosition, refresh);
                }

            }).catch((e) =>
            {
                setPosition(null);

                if (refresh) {
                    clearTimeout(timeout);
                    timeout = setTimeout(updatePosition, refresh);
                }
            });
        };

        timeout = setTimeout(updatePosition, 500);

        return () =>
        {
            clearTimeout(timeout);
        };

    }, [refresh]);

    return position;
};