import { en as reactEn } from '@ews/react-translations';


const en = {

  ...reactEn,

  /* navbar
  "Home": "home",
  "Systems": "Systems",
  "Customers": "Customers",
  "Users": "Users",
  */

  /* MainSettings */
  "Salutation.m": "Herr",
  "Salutation.f": "Frau",
  "Salutation.c": "Firma",
  "Salutation.other": "-",

  /* sidebar menu
  "Menu": "Menu",
  "Select language": "Select language",
  "My profile": "My profile",
  "Help": "Help",
  "Logout": "Logout",
  */

  /* locales */
  "en": "English",
  "de": "German",

  /* FilterCategory */

  /* System-List */
  "OperatingElements": "Operating elements",
  "ActuationElements": "Actuation elements",

  /* Customer-List */
  "PostalCode": "Postal code",
  "City": "City",
  "Country": "Country",
  "Address1": "Address 1",
  "Address2": "Address 2",



  "Customers-ID": "Customer ID",
  "CustomerNumber": "Customer number",
  "CustomerName": "Customer name",
  "SystemKey": "System key",
  "SystemName": "System name",
  "SystemNumber": "System ID",
  //"TD_None": "Keine",
  "FC_Alarm": "Alarm",
  "FC_Fault": "Fault",
  "FC_Disablement": "Disablement",
  "FC_TechnicalMessage": "Technical messages",
  "FC_ActiveOutput": "Active output",
  "FC_Confirmation": "Confirmation",
  "FC_ExtinguishingEvent": "Extinguishing event",
  "FC_TestAlarm": "Test alarm",
  "FC_TestCondition": "Test condition",
  "FC_FaultAlarm": "Fault alarm",
  "FC_Status": "Status",
  "FC_All": "All",

  "OPER_RANGE_SELECTED": "Range selected",
  "OPER_RANGE_BEGIN": "Begin selection",
  "OPER_RANGE_END": "Begin selection",

  // "OP_range_selected": "Range selected",
  // "OP_range_begin": "Begin selection",
  // "OP_range_end": "End selection",

  // "OP_disable": "Disable",
  // "OP_enable": "Enable",
  // "OP_activate": "Activate",
  // "OP_beginTest": "Test condition",
  // "OP_endTest": "End test condition",
  // "OP_silence": "Silence",
  // "OP_reactivate": "Reactivate",

  //CWI
  "Austria": "Austria",
  "Switzerland": "Switzerland",
  "Germany": "Germany",

  "CUSTOMERS_ID": "Client ID",
  "CUSTOMERS_NAME": "Client name",
  "CUSTOMERS_NAME_TEMPLATE": "Client name TEMPLATE",
  "CUSTOMERS_NAME2": "Client name 2",
  "CUSTOMERS_NAME3": "Client name 3",
  "CUSTOMERS_ADDRESS1": "Address 1",
  "CUSTOMERS_ADDRESS2": "Address 2",
  "CUSTOMERS_POSTAL_CODE": "Postal code",
  "CUSTOMERS_CITY": "City",
  "CUSTOMERS_COUNTRY": "Country",

  "REPORT_MAINTENANCE_LOG_ELEMENTS": "Elemente anzeigen",
  "REPORT_MAINTENANCE_LOG_ELEMENTS_TESTED": "Nur geprüfte",
  "REPORT_MAINTENANCE_LOG_ELEMENTS_UNTESTED": "Nur ungeprüfte",
  "REPORT_MAINTENANCE_LOG_DATE": "Wartung durchgeführt",
  "REPORT_MAINTENANCE_LOG_EVENT": "Prüfereignis",

  "SYSTEM_DETAILS": "Details",
  "AUTHORIZATION_BLOCKED_MESSAGE": "Der Benutzer %{username} mit der E-Mail Adresse %{email} ist bereits in Berechtigung. Daher ist ihr Einstieg derzeit nicht möglich.",
  "AUTHORIZATION_BLOCKED_PHONE_MESSAGE": "Der Benutzer %{username} mit der Mobiltelefonnummer %{phoneNumber} ist bereits in Berechtigung. Daher ist ihr Einstieg derzeit  nicht möglich."

};

export default en;
