import { useEffect, useState } from "react";
import
{
    useModifySystemPermissions,
    useSystem,
    useSystemPermissions
} from "../../../ReactData/system";

import { SystemPermissions } from "@ews/react-data";
import SettingsCol from "../../../Settings/SettingsCol/SettingsCol";
import SettingsCheckbox from "../../../Settings/SettingsComponents/SettingsCeckbox/SettingsCeckbox";
import SettingsSelect from "../../../Settings/SettingsComponents/SettingsSelect/SettingsSelect";
import SettingsSelectOption from "../../../Settings/SettingsComponents/SettingsSelectOption/SettingsSelectOption";
import SettingsTextInput from "../../../Settings/SettingsComponents/SettingsTextInput/SettingsTextInput";
import { PermissionsSettingsProps } from "./types";
import SettingsNumberInput from "../../../Settings/SettingsComponents/SettingsNumberInput/SettingsNumberInput";
import { NoDeviceNumber } from "@ews/zlt-events";

const PermissionsSettings: React.FC<PermissionsSettingsProps> = ({ systemId }) =>
{
    const system = useSystem(systemId);
    const permission = useSystemPermissions(systemId);
    const modifySystemPermissions = useModifySystemPermissions();

    function saveSystemPermission(key: keyof SystemPermissions, value: any)
    {
        if (!system.id || !(key in permission)) return;

        permission[key] = value;

        modifySystemPermissions(system.id!, { [key]: value } as SystemPermissions);
    }

    const [systemPermissionState, setSystemPermissionState] = useState<Partial<SystemPermissions>>({} as SystemPermissions);

    useEffect(() =>
    {
        if (!systemPermissionState.systemId && permission.systemId) {
            setSystemPermissionState(permission);
        }
    }, [system, permission]);

    return <>
        <SettingsCol >
            <SettingsTextInput
                lines="none"
                text={"Save position data for (min)"}
                value={`${permission.bufferGPSInAreaTime || 0}`}
                onBlur={({ value }) =>
                {
                    setSystemPermissionState({ ...systemPermissionState, bufferGPSInAreaTime: value } as SystemPermissions);
                    saveSystemPermission("bufferGPSInAreaTime", value);
                }} />
            <SettingsCheckbox
                text={"WLAN check active"}
                checked={permission.restrictOperationToWlanSSID || false}
                onChange={(restrictOperationToWlanSSID) =>
                {
                    setSystemPermissionState({ ...systemPermissionState, restrictOperationToWlanSSID } as SystemPermissions);
                    saveSystemPermission("restrictOperationToWlanSSID", restrictOperationToWlanSSID);
                }}>
                <SettingsTextInput
                    lines="none"
                    text={"WLAN SSID"}
                    value={permission.WlanSSID}
                    onBlur={({ value }) =>
                    {
                        if (permission.WlanSSID === value) return;
                        setSystemPermissionState({ ...systemPermissionState, WlanSSID: value } as SystemPermissions);
                        saveSystemPermission("WlanSSID", value);
                    }} />
            </SettingsCheckbox>

            <SettingsCheckbox
                text={"GPS check active"}
                checked={permission.restrictOperationToGPSArea || false}
                onChange={(restrictOperationToGPSArea) =>
                {
                    setSystemPermissionState({ ...systemPermissionState, restrictOperationToGPSArea } as SystemPermissions);
                    saveSystemPermission("restrictOperationToGPSArea", restrictOperationToGPSArea);
                }} />
            <SettingsCheckbox
                text={"Only one user in authorization"}
                checked={permission.exclusiveAuthorization || false}
                onChange={(exclusiveAuthorization) =>
                {
                    setSystemPermissionState({ ...systemPermissionState, exclusiveAuthorization } as SystemPermissions);
                    saveSystemPermission("exclusiveAuthorization", exclusiveAuthorization);
                }} />
            <SettingsCheckbox
                text={"Operation only possible if FDCP is in authorization level 2/3"}
                checked={permission.restrictOperationToAuthorizedUsers || false}
                onChange={(restrictOperationToAuthorizedUsers) =>
                {
                    setSystemPermissionState({ ...systemPermissionState, restrictOperationToAuthorizedUsers } as SystemPermissions);
                    saveSystemPermission("restrictOperationToAuthorizedUsers", restrictOperationToAuthorizedUsers);
                }} />
            <SettingsCheckbox
                text={"If session is enabled on the FDCP"}
                checked={permission.restrictOperationToApprovedSession || false}
                onChange={(restrictOperationToApprovedSession) =>
                {
                    setSystemPermissionState({ ...systemPermissionState, restrictOperationToApprovedSession } as SystemPermissions);
                    saveSystemPermission("restrictOperationToApprovedSession", restrictOperationToApprovedSession);
                }} />

        </SettingsCol>

        <SettingsCol >

            <SettingsCheckbox
                text={"Show buzzer field in APP"}
                checked={permission.exposeInternalBuzzer || false}
                onChange={(exposeInternalBuzzer) =>
                {
                    setSystemPermissionState({ ...systemPermissionState, exposeInternalBuzzer } as SystemPermissions);
                    saveSystemPermission("exposeInternalBuzzer", exposeInternalBuzzer);
                }} />

            <SettingsCheckbox
                text={"Show alarming device field in APP"}
                checked={permission.exposeAlarmingDevice || false}
                onChange={(exposeAlarmingDevice) =>
                {
                    setSystemPermissionState({ ...systemPermissionState, exposeAlarmingDevice } as SystemPermissions);
                    saveSystemPermission("exposeAlarmingDevice", exposeAlarmingDevice);
                }} >

                <SettingsNumberInput
                    lines="none"
                    text={"Nr.:"}
                    value={permission.alarmingDeviceFirstElement || 1}
                    onBlur={({ value }) =>
                    {
                        setSystemPermissionState({ ...systemPermissionState, alarmingDeviceFirstElement: value } as SystemPermissions);
                        saveSystemPermission("alarmingDeviceFirstElement", value);
                    }} />

                <SettingsNumberInput
                    lines="none"
                    text={"bis.:"}
                    value={permission.alarmingDeviceLastElement || NoDeviceNumber}
                    onBlur={({ value }) =>
                    {
                        setSystemPermissionState({ ...systemPermissionState, alarmingDeviceLastElement: value } as SystemPermissions);
                        saveSystemPermission("alarmingDeviceLastElement", value);
                    }} />

                <SettingsSelect
                    text={"Silence Device Authorization Level"}
                    value={permission.operateAeAuthLevel}
                    onBlur={({ value }) =>
                    {
                        setSystemPermissionState({ ...systemPermissionState, operateAeAuthLevel: value } as SystemPermissions);
                        saveSystemPermission("operateAeAuthLevel", value);
                    }}
                >
                    <SettingsSelectOption text={"Level 1"} value={"level1"} />
                    <SettingsSelectOption text={"Level 2/3"} value={"level2"} />

                </SettingsSelect>

            </SettingsCheckbox>

            <SettingsCheckbox
                text={"Show transmitting device field in APP"}
                checked={permission.exposeTransmittingDevice || false}
                onChange={(exposeTransmittingDevice) =>
                {
                    setSystemPermissionState({ ...systemPermissionState, exposeTransmittingDevice } as SystemPermissions);
                    saveSystemPermission("exposeTransmittingDevice", exposeTransmittingDevice);
                }}>

                <SettingsNumberInput
                    lines="none"
                    text={"Nr.:"}
                    value={permission.transmittingDeviceElement || 1}
                    onBlur={({ value }) =>
                    {
                        setSystemPermissionState({ ...systemPermissionState, transmittingDeviceElement: value });
                        saveSystemPermission("transmittingDeviceElement", value);
                    }} />

                <SettingsNumberInput
                    lines="none"
                    text={"Reaction timer"}
                    disabled
                    value={permission.transmittingDeviceReactionTimer || 0}
                />

                <SettingsNumberInput
                    lines="none"
                    text={"Exploration timer"}
                    disabled
                    value={permission.transmittingDeviceExplorationTimer || 0}
                />

            </SettingsCheckbox>
            <SettingsCheckbox
                text={"Show reset panel field in APP"}
                checked={permission.exposePanelReset || false}
                onChange={(exposePanelReset) =>
                {
                    setSystemPermissionState({ ...systemPermissionState, exposePanelReset });
                    saveSystemPermission("exposePanelReset", exposePanelReset);
                }} />
        </SettingsCol>
        <SettingsCol >
            <></>
        </SettingsCol>
    </>;
};

export default PermissionsSettings;