import packageInfo from '../../../../package.json';

import
{
    useL10n
} from "@ews/react-localization-context";

import
{
    IonButton,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonSelect,
    IonSelectOption,
    useIonAlert,
    useIonRouter,
    useIonToast
} from "@ionic/react";

import
{
    help,
    lockClosed,
    lockOpen,
    person,
    power
} from "ionicons/icons";

import
{
    FC, FormEvent, ReactElement, useState
} from "react";

import
{
    MenuItem,
    MenuLink,
    Sidebar,
    SidebarSection,
} from "./Sidebar";

import
{
    MenuToggleProps
} from "../types";

import
{
    useNamedRoutes
} from "../../NamedRoutes";

import
{
    AuthorizationLevel
} from "@ews/zlt-events";

import
{
    useAuthorization,
    usePermitAuthorization
} from "../../Authorization";

import
{
    ReactClickEvent
} from "../../types";

import
{
    useAuth,
    useMyPermissions,
    useMyProfileId,
    //useSendDeveloperInformation
} from "../../Login/LoginProvider";

import
{
    AuthorizeResponse,
    UserPermissions,
    permissionAllowed
} from "@ews/react-data";

import style from './Main.module.scss';

type MenuToggleEvent = ReactClickEvent;
type BlurEvent = React.FocusEvent<HTMLIonInputElement>;

export type MainSidebarProps = MenuToggleProps & {
    children?: ReactElement;
};

export type AcquireAuthorizationProps = MenuToggleProps & {
    systemId: string;
};

export const AcquireAuthorization: FC<AcquireAuthorizationProps> = ({
    onMenuToggle,
    systemId
}) =>
{
    const { authorize, authorizationLevel } = useAuthorization();
    const authorizationPermitted = usePermitAuthorization(systemId);
    const [code2, setCode2] = useState("");
    const [code3, setCode3] = useState("");

    const [presentAlert] = useIonAlert();

    const { translate: t } = useL10n();
    const permissions: UserPermissions = useMyPermissions();

    const showMessage = (message: string) =>
    {
        presentAlert({
            "header": t("Authorization failed"),
            "message": t(message),
            "cssClass": style.alertMessage,
            "buttons": [{
                text: t('Dismiss'),
                role: 'cancel'
            }]
        });
    };

    const authorizeWrapper = async (e: MenuToggleEvent, level: AuthorizationLevel, code?: string) =>
    {
        try {
            const result = await authorize(level, code);

            if (result === true) {

                onMenuToggle && onMenuToggle(e);
                return true;
            } else {

                if (result) {

                    if ('phoneNumber' in result) {
                        showMessage(t('AUTHORIZATION_BLOCKED_PHONE_MESSAGE', result));
                    } else {
                        showMessage(t('AUTHORIZATION_BLOCKED_MESSAGE', result));
                    }
                }
            }

        } catch (e) {
            showMessage(t(e as string));
        }
    };

    const handleForm = async (e: FormEvent, authorizationLevel: AuthorizationLevel) =>
    {
        e.preventDefault();

        const code = authorizationLevel === AuthorizationLevel.LEVEL2 ? code2 : code3;
        await authorizeWrapper(e as unknown as MenuToggleEvent, authorizationLevel, `${code}`);

        setCode2("");
        setCode3("");
    };

    if (!authorizationPermitted) return <></>;

    if (authorizationLevel > AuthorizationLevel.LEVEL1) {
        return (
            <IonItem detail={false} onClick={(e) => authorizeWrapper(e, AuthorizationLevel.LEVEL1)} button={true} >
                <IonLabel>{t('Exit Authorization')}</IonLabel>
                <IonIcon icon={lockClosed} />
            </IonItem>
        );
    }

    return (
        <>
            {permissionAllowed(permissions.permitAccessLevel2!) ? <form onSubmit={(e) => handleForm(e, AuthorizationLevel.LEVEL2)}><MenuItem>
                <IonLabel position="stacked">{t('Authorization Level %{authorizationLevel}', { authorizationLevel: '2' })}</IonLabel>
                <IonInput value={code2} onIonInput={(e) => setCode2(e.detail.value || "")} label="" placeholder={t('Enter Code')} />
                <IonButton type='submit' color={'light'} slot="end" ><IonIcon icon={lockOpen}></IonIcon></IonButton>
            </MenuItem ></form> : <></>
            }

            {permissionAllowed(permissions.permitAccessLevel3!) ? <form onSubmit={(e) => handleForm(e, AuthorizationLevel.LEVEL3)}><MenuItem>
                <IonLabel position="stacked">{t('Authorization Level %{authorizationLevel}', { authorizationLevel: '3' })}</IonLabel>
                <IonInput value={code3} onIonInput={(e) => setCode3(e.detail.value || "")} label="" placeholder={t('Enter Code')} />
                <IonButton type='submit' color={'light'} slot="end"><IonIcon icon={lockOpen}></IonIcon></IonButton>
            </MenuItem ></form> : <></>
            }

        </>
    );

};

const LanguageSelector: FC = () =>
{
    const { translate: t, getLocale, getLocales } = useL10n();
    const routes = useNamedRoutes();
    const router = useIonRouter();

    const localeChange = (e: CustomEvent) =>
    {
        const locale = e.detail.value;
        router.push(routes.generate(null, { locale }));
    };

    return (
        <MenuItem>
            <IonSelect
                onIonChange={localeChange}
                value={getLocale()}
                interface="popover"
                style={{ width: "100%" }}
                placeholder={t('Select language')}
            >
                {getLocales().map((locale) =>
                {
                    return (
                        <IonSelectOption
                            key={locale}
                            value={locale}
                        >
                            {t(locale)}
                        </IonSelectOption>
                    );
                })}

            </IonSelect>
        </MenuItem>
    );
};

const Main: FC<MainSidebarProps> = ({ onMenuToggle, children }) =>
{
    const { translate: t } = useL10n();
    const { generate } = useNamedRoutes();
    const { logout } = useAuth();
    const me = useMyProfileId();
    //const [token, setToken] = useState("");
    //const sendDeveloperInformation = useSendDeveloperInformation();

    //NotificationPlugin.getToken().then((result) => setToken(result.value));

    return (
        <Sidebar title={t('Menu')} onMenuToggle={onMenuToggle}>
            <SidebarSection grow>
                <LanguageSelector />
                <MenuLink
                    title={t('My profile')}
                    onClick={onMenuToggle}
                    link={me ? generate('user', { id: me }) : ""}
                    icon={person}
                />
            </SidebarSection>
            {children && <SidebarSection>{children}</SidebarSection>}
            <SidebarSection>
                <MenuLink
                    title={t('Help')}
                    onClick={onMenuToggle}
                    link={generate('home')}
                    icon={help}
                />
                <MenuLink
                    title={t('[SID_DLG_HEADER_AUTHEND]')}
                    onClick={logout}
                    // onClick={onMenuToggle}
                    // link={generate('home')}
                    icon={power}
                />

                <MenuItem className={style.versionInfo}>
                    {packageInfo.version}
                </MenuItem>
                {/*                {token && <MenuLink
                    title={t('Dev Info')}
                    onClick={() => sendDeveloperInformation(token)}
                />} */}

            </SidebarSection>

        </Sidebar >
    );

};

export default Main;;;