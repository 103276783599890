import
{
    UserPermissions,
    permissionAllowed,
    permissionAvailable,
    permissionEmpty
} from "@ews/react-data";

import { useModifyUserPermissions, useUser, useUserPermissions } from "../../../ReactData/user";
import SettingsCol from "../../../Settings/SettingsCol/SettingsCol";
import SettingsCheckbox from "../../../Settings/SettingsComponents/SettingsCeckbox/SettingsCeckbox";
import { PermissionsSettingsProps } from "./types";
import { useMyProfileId } from "../../../Login/LoginProvider";

const PermissionsSettings: React.FC<PermissionsSettingsProps> = ({ id }) =>
{
    const me = useMyProfileId();
    const user = useUser(id);
    const userPermission = useUserPermissions(id);
    const modifyPermission = useModifyUserPermissions();


    const modify = async (data: UserPermissions) =>
    {
        await modifyPermission(user.id!, data);
    };

    const setPermission = async (permission: keyof UserPermissions, value: boolean) =>
    {
        return modify({ [permission]: value ? 1 : 0 } as unknown as UserPermissions);
    };

    if (me === id) return <></>;

    return <>
        <SettingsCol>
            <SettingsCheckbox
                text={"Sub-Admin"}
                disabled={!permissionAvailable(userPermission.isAdmin!)}
                checked={permissionAllowed(userPermission.isAdmin!)}
                onChange={(checked) => { setPermission('isAdmin', checked); }} />
            {/* <SettingsCheckbox text={"Can set licences for webshop"} checked={RightsDataRef.current.licencesWebshop} onChange={(checked) => { RightsDataRef.current.licencesWebshop = checked; }} /> */}
            <SettingsCheckbox
                text={"is allowed to log in on multiple devices"}
                checked={userPermission.allowMultipleLogin || false}
                onChange={(allowMultipleLogin) => { modify({ allowMultipleLogin } as UserPermissions); }} />
            <SettingsCheckbox
                text={"is allowed toto create new users"}
                disabled={!permissionAvailable(userPermission.createUser!)}
                checked={permissionAllowed(userPermission.createUser!)}
                onChange={(createUser) => { setPermission("createUser", createUser); }} />
            <SettingsCheckbox
                text={"is allowed to create new clients"}
                disabled={!permissionAvailable(userPermission.createCustomer!)}
                checked={permissionAllowed(userPermission.createCustomer!)}
                onChange={(createCustomer) => { setPermission("createCustomer", createCustomer); }} />
            <SettingsCheckbox
                text={"Can view all visible systems"}
                disabled={!permissionAvailable(userPermission.listAllSystems!)}
                checked={permissionAllowed(userPermission.listAllSystems!)}
                onChange={(listAllSystems) => { setPermission("listAllSystems", listAllSystems); }} />
            <SettingsCheckbox
                text={"is allowed to create and connect systems"}
                disabled={!permissionAvailable(userPermission.grantSystemAccess!)}
                checked={permissionAllowed(userPermission.grantSystemAccess!)}
                onChange={(grantSystemAccess) => { setPermission("grantSystemAccess", grantSystemAccess); }} />
            <SettingsCheckbox
                text={"is allowed to add new enablements"}
                disabled={!permissionAvailable(userPermission.createShare!)}
                checked={permissionAllowed(userPermission.createShare!)}
                onChange={(createShare) => { setPermission("createShare", createShare); }} />
            <SettingsCheckbox
                text={"Autorization level 2 allowed"}
                disabled={!permissionAvailable(userPermission.permitAccessLevel2!)}
                checked={permissionAllowed(userPermission.permitAccessLevel2!)}
                onChange={(permitAccessLevel2) => { setPermission("permitAccessLevel2", permitAccessLevel2); }} />
            <SettingsCheckbox
                text={"Autorization level 3 allowed"}
                disabled={!permissionAvailable(userPermission.permitAccessLevel3!)}
                checked={permissionAllowed(userPermission.permitAccessLevel3!)}
                onChange={(permitAccessLevel3) => { setPermission("permitAccessLevel3", permitAccessLevel3); }} />
            <SettingsCheckbox
                text={"Receives notifications of lover level systems"}
                disabled={!permissionAvailable(userPermission.notifyAllSystem!)}
                checked={permissionAllowed(userPermission.notifyAllSystem!)}
                onChange={(notifyAllSystem) => { setPermission("notifyAllSystem", notifyAllSystem); }} />

        </SettingsCol>
        <SettingsCol>
            <SettingsCheckbox
                text={"Analogue detector values also avaliable in authorization level 2"}
                checked={userPermission.detectorValuesAccessLevel2 || false}
                onChange={(detectorValuesAccessLevel2) => { modify({ detectorValuesAccessLevel2 } as UserPermissions); }} />
            <SettingsCheckbox
                text={"List view in authorizatzon level 1"}
                checked={userPermission.listViewAccessLevel1 || false}
                onChange={(listViewAccessLevel1) => { modify({ listViewAccessLevel1 } as UserPermissions); }} />
            <SettingsCheckbox
                text={"Operation only possible if session is enabled on the FDCP"}
                disabled={permissionAvailable(userPermission.restrictOperationToApprovedSession!)}
                checked={!permissionEmpty(userPermission.restrictOperationToApprovedSession!)}
                onChange={(restrictOperationToApprovedSession) => { setPermission("restrictOperationToApprovedSession", restrictOperationToApprovedSession); }} />

        </SettingsCol>
        <SettingsCol>

        </SettingsCol>
    </>;
};

export default PermissionsSettings;